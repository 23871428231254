import React, { useContext, useState, useEffect, Fragment } from "react";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Ensure to include this to handle default marker icon issues
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/images/marker-icon.png';

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import View from "../../../../assets/images/Group.svg";
import Export from "../../../../assets/images/Edit-Camunication.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { getDrivers } from "../../../../store/driverSlice";
import { getvehicleList } from "../../../../store/vehicleListSlice";
import { getTripActivity } from "../../../../store/tripActivitySlice";
import { getDriverRanking } from "../../../../store/driverRankingSlice";
import { getVehicleAlert } from "../../../../store/VehicleAlertSlice";
import { getAcceleration } from "../../../../store/accelerationSlice";
import image_placeholder from '../../../../assets/images/image_placeholder.jpg'
import ImportUser from "../../../../assets/images/imagesuser.png";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";

import CommonDatePicker from "../../../../sharedComponent/CommonDatePicker";
import Import from "../../../../assets/images/ic-Import.svg";
import ApiConfig from "../../../../api/ApiConfig";
import { getWithAuthCall, simpleGetCall, simplePostCall } from "../../../../api/ApiServices";
import { notifyError } from "../../../../sharedComponent/notify";
import { Value } from "sass";
import Loader from "../../../../sharedComponent/Loader";
import NoDataComp from "../../../../sharedComponent/NoDataComp";
import { Select } from "antd";
import MapComponent from "../../../../sharedComponent/MapComponent";
import GoogleMapComponent from "../../../../sharedComponent/GoogleMap/GoogleMapComponent";

const { Option } = Select;


const CenterMap = ({ center }) => {
  const map = useMap();

  // Ensure center coordinates are valid
  const isValidCenter =
    Array.isArray(center) &&
    center?.length === 2 &&
    !isNaN(center[0]) &&
    !isNaN(center[1]) &&
    center[0] >= -90 &&
    center[0] <= 90 &&
    center[1] >= -180 &&
    center[1] <= 180;

  useEffect(() => {
    if (isValidCenter) {
      // Only update view if the center is valid
      map?.setView(center, map?.getZoom());
    } else {
      console.error("Invalid center coordinates:", center);
    }
  }, [center, map, isValidCenter]);

  return null;
};
const GSMSingnalReports = () => {




  const [selectedDriver, setSelectedDriver] = useState(0);
  // const [vehicleList, setvehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const drivers = useSelector((state) => state.driver.drivers);
  // const vehicleList = useSelector((state) => state.vehicleList.vehicleList);
  const [vehicleList, setvehicleList] = useState([]);
  const tripActivity = useSelector((state) => state.tripActivity.tripActivity);
  const [VehicleId, setVehicleId] = useState("");
  const [location, setLocation] = useState([])
  const toDayDate = new Date()
  const [currentDate, setCurrentDate] = useState("");
  const { sidebar, setSidebar, customerData, LinkReport, setOptionDynamicDownload, setDark, googleMap, recordsPerPage,
  } =
    useContext(AppContext);
  console.log("customerData33333333333333", customerData);
  console.log("LinkReport  >>>>>>>>>>>  ", LinkReport);
  /*  const getdistanceunit = localStorage.customer_distance_unit
     ? localStorage.customer_distance_unit
     : ""; */
  const { t, i18n } = useTranslation();
  const getdistanceunit = localStorage.getItem('customer_distance_unit') || '';
  const translatedDistanceUnit = t(getdistanceunit);


  const [cardDetails, setCardDetails] = useState([]);
  useEffect(() => {

    getFilterPointofintrest()
  }, [VehicleId, currentDate])

  const dateFormat = [51.505, -0.09]
  
  console.log("GSM Date >>>>>>>>>",currentDate.toDayDate)

  
  const getFilterPointofintrest = () => {
    setLoading(true)
    let newData =currentDate.toDayDate
    let body = {
      "report_date": newData,
      "vehicle": VehicleId
    }
    simplePostCall(
      ApiConfig.GSM_REPORT_, JSON.stringify(body)
    )
      .then((res) => {
       
        if (res.result === true) {
          setLoading(false)

          setLocation(res.location)
          console.log("Location Data ", res)
        } else {
          setLocation([]);
          setLoading(false)

        }
      })
      .catch((err) => {
        console.log("Error response,", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  console.log("map Data static>>>>>>>>>", cardDetails)

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };


  const dispatch = useDispatch();
  const params = useParams();
  const maxDate = new Date();

  const [startDate, setStartDate] = useState({ DateToday: new Date() });
  const [OrderListData, setOrderListData] = useState([]);
  const [pdfData, setpdfData] = useState("");
  console.log("pdfData", pdfData);
  const [NextPage, setNextPage] = useState(true);
  console.log("NextPage", NextPage);

  const [optionData, setOptionData] = useState("date");
  const [DriverId, setDriverId] = useState("");
  const [DriverList, setDriverList] = useState([]);
  console.log(optionData);
  const [daysToAdd, setDaysToAdd] = useState(30);
  const [NextDateShow, setNextDateShow] = useState({ toNextDate: new Date() });
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
  const [endDayOfMonth, setEndDayOfMonth] = useState(new Date());
  const [OptionDownload, setOptionDownload] = useState("");
  const [EndDate, setEndDate] = useState({ toDayEnd: new Date() });

  // State for weekStartDate and weekEndDate
  const [weekStartDate, setWeekStartDate] = useState(new Date());

  const [weekEndDate, setWeekEndDate] = useState(new Date());



  



 



  useEffect(() => {
    getVehicelList();
  }, [,]);

  function getVehicelList() {
    getWithAuthCall(ApiConfig.VEHICEL_AVALIVALITY_DROPDAWAN)
      .then((data) => {
        setvehicleList(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
 
  const hasLocations = location?.length > 0;
  const center = hasLocations ? [
    parseFloat(location[0].latitude),
    parseFloat(location[0].longitude)
  ] : [24.317001, 54.542664];
  const getMarkerIcon = (strength) => {
    let color;

    if (strength === 100) color = '#97f972';
    else if (strength >= 50) color = 'yellow';
    else if (strength >= 20) color = 'orange';
    else color = 'red';

    return L.divIcon({
      className: 'custom-icon',
      html: `
        <div style="
          width: 12px; height: 12px;
          background-color: ${color};
          border-radius: 50%;
          border: 1px solid black;
        "></div>
      `,
      iconSize: [12, 12],
      iconAnchor: [6, 12]
    });
  };


  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Vehicle_inspition_dashboard heightFixed">
        <div className="report-viwe-head" id="Viwe_Report_Respoasive">
          <p></p>
        </div>



        <div className="col-lg-12 mt-0">
          <div className="UserTabel">
            <div className="heading">
              <div>
                <p>
                  <p>
                    {/* {t("Date:")} {formattedNextDate()} */}
                  </p>
                </p>
              </div>
              <div className="d-flex ">
              <div className="multi-select-1 mr-2">
                <Select
                  style={{ height: "40px", width: "180px" }}
                  className="custom-select"
                  placeholder={t("Vehicle list")}
                  onChange={(value) => setVehicleId(value)}
                >
                  <Option value="" style={{ color: "rgba(156, 73, 0)" }}>
                    {t("Vehicle")}
                  </Option>
                  {vehicleList?.map((vehicle) => (
                    <Option
                      value={vehicle.vehicle_id}
                      style={{ color: "rgba(156, 73, 0)" }}
                    >
                      {vehicle.vehicle_number}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="innerSelectBox weekCounter datepicker-main">
                <CommonDatePicker
                  dateKey={"toDayDate"}
                  setDate={setCurrentDate}
                  data={currentDate}
                  SetPlaceholder={t("Today Date")}
                  dataDisbal={maxDate}
                />
              </div>
              </div>
           
              <div className="right-export-btn-section-wrapper">
                <div className="c-pointer me-2"></div>

              
              </div>
            </div>
<div>
  <div className="row m-2">

    <div className="col-md-1 d-flex align-items-center">
    <span className="color-box green"></span>
    Good
    </div>
    <div className="col-md-1 d-flex align-items-center">
    <span className="color-box yellow"></span>
    Average
    </div>
    <div className="col-md-1 d-flex align-items-center">
    <span className="color-box orange"></span>
    Poor
    </div>
    <div className="col-md-2 d-flex align-items-center ">

    <span className="color-box red"></span>
    Very Poor
    </div>

  </div>
  </div>
            <div className="m-2">
              {loading ? (<Loader />) : (
                <MapContainer
                  center={center}
                  zoom={13}
                  scrollWheelZoom={true}
                  style={{ height: '80vh', width: '100%' }}
                >
                  <CenterMap center={center} />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />

                  {location?.map((loc, index) => {
                    const position = [parseFloat(loc.latitude), parseFloat(loc.longitude)];
                    console.log(`Rendering Marker ${index} at`, position);
                    const icon = getMarkerIcon(loc.gsm_signal_strength);
                    return (
                      <Marker
                        key={index}
                        position={position}
                        icon={icon}
                      >
                        <Popup>
                          Vehicle: {loc.vehicle_number}<br />
          
                          Signal Strength: {loc.gsm_signal_strength}
                        </Popup>
                      </Marker>
                    );
                  })}
                </MapContainer>
              )}

            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default GSMSingnalReports;
